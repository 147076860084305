
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg height="24" widgth="24" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.8225 4.00001L7.33083 1.49751C7.44068 1.38766 7.50239 1.23868 7.50239 1.08334C7.50239 0.927997 7.44068 0.779017 7.33083 0.669173C7.22099 0.559329 7.07201 0.49762 6.91667 0.49762C6.76132 0.49762 6.61235 0.559329 6.5025 0.669173L4 3.17751L1.4975 0.669173C1.38766 0.559329 1.23868 0.49762 1.08333 0.49762C0.927991 0.49762 0.779011 0.559329 0.669167 0.669173C0.559323 0.779017 0.497614 0.927997 0.497614 1.08334C0.497614 1.23868 0.559323 1.38766 0.669167 1.49751L3.1775 4.00001L0.669167 6.50251C0.614492 6.55674 0.571096 6.62125 0.541481 6.69234C0.511866 6.76342 0.496618 6.83967 0.496618 6.91667C0.496618 6.99368 0.511866 7.06992 0.541481 7.14101C0.571096 7.21209 0.614492 7.27661 0.669167 7.33084C0.723396 7.38551 0.787913 7.42891 0.858998 7.45853C0.930082 7.48814 1.00633 7.50339 1.08333 7.50339C1.16034 7.50339 1.23659 7.48814 1.30767 7.45853C1.37876 7.42891 1.44327 7.38551 1.4975 7.33084L4 4.82251L6.5025 7.33084C6.55673 7.38551 6.62125 7.42891 6.69233 7.45853C6.76342 7.48814 6.83966 7.50339 6.91667 7.50339C6.99367 7.50339 7.06992 7.48814 7.141 7.45853C7.21209 7.42891 7.27661 7.38551 7.33083 7.33084C7.38551 7.27661 7.42891 7.21209 7.45852 7.14101C7.48814 7.06992 7.50338 6.99368 7.50338 6.91667C7.50338 6.83967 7.48814 6.76342 7.45852 6.69234C7.42891 6.62125 7.38551 6.55674 7.33083 6.50251L4.8225 4.00001Z" fill="#1D2433"/>
</svg>

  </template>

  <script>
  export default {
    name: 'IconClose',
    inheritAttrs: true,
  }
  </script>
